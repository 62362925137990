<template>
  <div id="addModify">
    <a-page-header
      :title="$route.query.isText == 0 ? '添加报告' : '编辑报告'"
      style="padding-left: 0; margin-bottom: 10px; border-bottom: 1px solid rgb(235, 237, 240)"
    />
    <a-row style="margin-top: 50px">
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-row>
          <a-col :span="12">
            <a-form-model-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" label="类型" prop="reportType">
              <a-radio-group v-model="form.reportType">
                <a-radio
                  :disabled="Disable"
                  @change="radioChange()"
                  :value="item.commonsValue"
                  v-for="item in reportTypeList"
                >
                  {{ item.commonsValueName }}
                </a-radio>
                <!-- <a-radio :disabled="Disable" :value="1"> 分析报告 </a-radio> -->
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              ref="name"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 19 }"
              label="报告名称"
              prop="reportName"
            >
              <a-input
                :disabled="Disable"
                v-model="form.reportName"
                @keydown.native="keydown($event)"
                style="width: 250px"
                placeholder="请输入"
                :maxLength="40"
                @blur="
                  () => {
                    $refs.name.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" label="所属分类" prop="classifyId">
              <a-select
                :getPopupContainer="
                  (triggerNode) => {
                    return triggerNode.parentNode || document.body;
                  }
                "
                placeholder="请选择"
                option-filter-prop="children"
                style="width: 250px"
                :maxTagCount="1"
                :maxTagTextLength="8"
                :filter-option="filterOption"
                mode="multiple"
                v-model="form.classifyId"
              >
                <a-select-option v-for="(v, i) in classTypeList" :key="i" :value="v.id"
                  >{{ v.classifyName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12" v-if="form.reportType != '2'">
            <a-form-model-item label="缩略图" :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" prop="thumbnailUrl">
              <a-upload
                list-type="picture-card"
                :action="IMG_API + '/oss/files'"
                class="avatar-uploader"
                :file-list="form.thumbnailUrl"
                @change="handleChange"
                @preview="handlePreview"
                :before-upload="beforeUpload"
              >
                <div v-if="form.thumbnailUrl.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">上传图片</div>
                </div>
              </a-upload>

              <span class="uploadTip">4:3的png/jpg/jpeg格式的图片,大小不可超过3M</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="12" v-if="form.reportType != '2'">
            <a-form-model-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" label="报告链接" prop="reportUrl">
              <a-input :disabled="Disable" v-model="form.reportUrl" style="width: 250px" placeholder="请输入" />
            </a-form-model-item>
          </a-col>
          <!-- <a-col v-if="form.reportType != '2'" :span="12">
            <a-form-model-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" label="链接适配" prop="linkAdapt">
              <a-checkbox-group
                v-model="form.linkAdapt"
                name="checkboxgroup"
                :options="plainOptions"
                :disabled="Disable"
              />
            </a-form-model-item>
          </a-col> -->
          <!-- <a-col v-if="form.reportType != '2'" :span="12">
            <a-form-model-item
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 19 }"
              label="报告配置平台"
              prop="reportPlatform"
            >
              <a-radio-group v-model="form.reportPlatform">
                <a-radio :disabled="Disable" v-for="(item, index) in reports" :key="index" :value="item.id">
                  {{ item.commonsValueName }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col> -->
          <a-col v-if="form.reportType == '2'" :span="12">
            <a-form-model-item
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 19 }"
              label="报告附件"
              prop="reportDocumentUrl"
            >
              <div class="up_file">
                <div class="up_file_width">
                  <a-upload
                    name="file"
                    :file-list="form.reportDocumentUrl"
                    :action="IMG_API + '/oss/files'"
                    :show-file-list="false"
                    @change="handleChangeFile"
                    :before-upload="beforeUploadFile"
                  >
                    <a-button v-if="form.reportDocumentUrl.length === 0"> <a-icon type="upload" /> 点击上传 </a-button>
                  </a-upload>
                </div>
                <span class="uploadTip">上传PDF格式的文件，文件不超过50M</span>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 19 }"
              label="报告标签"
              prop="reportLableInfo"
            >
              <div class="mb-10">
                <a-select
                  collapse-tags
                  :maxTagCount="1"
                  :maxTagTextLength="8"
                  :getPopupContainer="
                    (triggerNode) => {
                      return triggerNode.parentNode || document.body;
                    }
                  "
                  style="width: 250px"
                  v-model="form.reportLableInfo"
                  mode="multiple"
                  placeholder="请选择"
                >
                  <a-select-option v-for="(v, i) in labelSelect" :key="i" :value="v.labelId"
                    >{{ v.labelName }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="uploadTip">多选可全选，没有则新增，标签不可超过12个字</div>
              <div class="uploadTip">配置后，有此标签权限的用户可查看此报告</div>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" label="用户标签" prop="userLableInfo">
              <div class="mb-10">
                <a-select
                  collapse-tags
                  :maxTagCount="1"
                  :maxTagTextLength="8"
                  :getPopupContainer="
                    (triggerNode) => {
                      return triggerNode.parentNode || document.body;
                    }
                  "
                  style="width: 250px"
                  v-model="form.userLableInfo"
                  mode="multiple"
                  placeholder="请选择"
                >
                  <a-select-option v-for="(v, i) in userType('用户标签')" :key="i" :value="v.labelId"
                    >{{ v.labelName }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="uploadTip">多选可全选</div>
              <div class="uploadTip">配置后，有此标签的用户可查看此报告</div>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" label="报告状态" prop="reportStatus">
              <a-radio-group :disabled="Disable" v-model="form.reportStatus">
                <a-radio :value="0" name="reportStatus"> 启用 </a-radio>
                <a-radio :value="1" name="reportStatus"> 禁用 </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-row>
    <div class="fotter">
      <a-button key="back" @click="close"> 取消 </a-button>
      <a-button
        :disabled="Disable"
        key="submit"
        style="margin-left: 30px"
        type="primary"
        :loading="loading"
        @click="onSubmit"
        >确认保存
      </a-button>
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewHandleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
const plainOptions = [
  { label: "PC", value: "0" },
  { label: "H5(新标签页打开)", value: "1" },
];

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
import { mapActions, mapState } from "vuex";
import { IMG_API } from "@/config";
export default {
  data() {
    return {
      plainOptions,
      IMG_API: IMG_API,
      loading: false,
      Disable: false, //禁用
      previewVisible: false,
      checkSize: true,
      upFileType: true,
      previewImage: "", //预览图片地址
      isBlurType: false, //判断是输入还是选择
      blurText: "", //失去焦点获取文案
      website: "Https://", //https
      Suffix: ".com", //后缀名
      form: {
        linkAdapt: ["0"], //链接适配
        classifyId: [], //分类id
        reportType: "0", //报告类型
        reportStatus: 0, //状态
        reportLableInfo: [], //报告标签id
        reportName: "", //报告名称
        reportUrl: "", // 报告链接
        userLableInfo: [], //用户标签
        thumbnailUrl: [], //文件集合
        reportDocumentUrl: [], //报告附件集合
        reportDocumentName: "", //报告附件名称
      },
      rules: {
        reportName: [
          { required: true, message: "请输入报告名称", trigger: "blur" },
          { min: 1, max: 40, message: "最大长度不能超过40个字符", trigger: "blur" },
        ],
        reportType: [{ required: true, message: "请选择类型", trigger: "change" }],
        classifyId: [{ required: true, message: "请选择分类", trigger: "change" }],
        reportStatus: [{ required: true, message: "请选择报告状态", trigger: "change" }],
        linkAdapt: [{ required: true, message: "请选择链接适配", trigger: "change" }],
        thumbnailUrl: [{ required: true, message: "请选择缩略图", trigger: "change" }],
        reportDocumentUrl: [{ required: true, message: "请上传报告附件", trigger: "change" }],
        reportUrl: [
          { required: true, message: "请输入链接", trigger: "blur" },
          {
            pattern: /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/,
            message: "请输入正确的网址",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    seeQuery(value) {
      if (value) {
        if (value.classifyId) {
          value.classifyId = value.classifyId.split(",");
        } else {
          value.classifyId = [];
        }
        if (value.reportLableInfo) {
          value.reportLableInfo = value.reportLableInfo.split(",");
        } else {
          value.reportLableInfo = [];
        }
        if (value.userLableInfo) {
          value.userLableInfo = value.userLableInfo.split(",");
        } else {
          value.userLableInfo = [];
        }
        //图片回显
        let img = [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: value.thumbnailUrl,
          },
        ];
        value.thumbnailUrl = img;
        if (value.linkAdapt === 2) {
          //链接适配回显处理
          value.linkAdapt = ["0", "1"];
        } else {
          value.linkAdapt = [value.linkAdapt.toString()];
        }
        let file = [
          {
            uid: "-1",
            name: value.reportDocumentName,
            status: "done",
            url: value.reportDocumentUrl,
          },
        ];
        value.reportType = value.reportType.toString();
        value.reportDocumentUrl = file;
        // 根据类型获取分类
        this.getClassSelectList({ type: value.reportType });
        this.form = value;
      }
    },
  },
  created() {
    this.labelManagement({ isAll: 1 });
    //判断是否为编辑
    if (this.$route.query && this.$route.query.id != null) {
      this.Disable = JSON.parse(this.$route.query.Disable);
      this.seequeryReport(this.$route.query.id);
    } else {
      this.getClassSelectList({ type: 0 });
    }
  },
  computed: {
    ...mapState({
      dataList: (state) => state.label.dataArr,
      seeQuery: (state) => state.allReports.seeQuery,
      classify: (state) => state.allReports.classify,
      list: (state) => state.allReports.list,
      reports: (state) => state.allReports.reports,
      total: (state) => state.allReports.total,
      reportTypeList: (state) => state.allReports.reportTypeList,
      labelSelect: (state) => state.dashboard.labelSelect,
      classTypeList: (state) => state.dashboard.classTypeList,
    }),
  },
  methods: {
    ...mapActions({
      addReportList: "allReports/addReportList",
      labelManagement: "label/labelManagement",
      createLabel: "label/createLabel",
      seequeryReport: "allReports/seequeryReport",
      platformCollection: "allReports/platformCollection",
      getAllclassify: "allReports/getAllclassify",
      updateReportList: "allReports/updateReportList",
      getClassSelectList: "dashboard/getClassSelectList",
    }),
    //模糊查询根据当前得数据返回input中相匹配得值.
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    //对https路径封装
    SuffixFn(value) {
      this.Suffix = value;
    },
    websiteFn(value) {
      this.website = value;
    },
    //获取所有标签 过滤出是用户标签 还是  报告标签
    userType(types) {
      let arr = this.dataList.filter((item) => {
        return item.labelType == types;
      });
      return arr;
    },
    //标识符 => 点击还是输入
    IsBlur(value) {
      this.isBlurType = true;
      this.blurText = value;
    },
    // 禁止输入空格
    keydown(e) {
      if (e.keyCode == 32) {
        e.returnValue = false;
      }
    },
    // 类型change
    radioChange() {
      this.form.reportDocumentUrl = [];
      this.form.reportUrl = "";
      this.form.classifyId = [];
      this.classTypeList = [];
      this.form.thumbnailUrl = [];
      this.$nextTick(() => {
        this.getClassSelectList({ type: this.form.reportType });
      });
    },
    //报告标签 change事件
    reportLabelChange(value) {
      this.isBlurType = false;
      this.form.reportLableInfo = value;
    },
    //用户标签 change事件
    UserLabelFn(value) {
      this.form.userLableInfo = value;
    },
    //alert
    success(message) {
      this.$message.success(message);
    },
    error(message) {
      this.$message.error(message);
    },
    warning(message) {
      this.$message.warning(message);
    },
    //处理新建数据
    handleNewData() {
      let formData = JSON.parse(JSON.stringify(this.form));
      formData.classifyId = formData.classifyId.join(",");
      formData.reportLableInfo = formData.reportLableInfo.join(",");
      formData.userLableInfo = formData.userLableInfo.join(",");
      if (formData.reportType === "2") {
        //判断类型是否为分析报告
        formData.thumbnailUrl = "";
        formData.reportUrl = "";
        formData.linkAdapt = null;
        formData.reportDocumentUrl = this.form.reportDocumentUrl[0].response.redirect_uri;
        formData.reportDocumentName = this.form.reportDocumentUrl[0].response.name;
      } else {
        formData.reportDocumentUrl = "";
        formData.reportDocumentName = "";
        formData.thumbnailUrl = formData.thumbnailUrl[0].response.redirect_uri;
        if (formData.linkAdapt.length > 1) {
          //判断链接适配是否全部选中，全中传2，
          formData.linkAdapt = 2;
        } else {
          formData.linkAdapt = Number(formData.linkAdapt[0]);
        }
      }
      return formData;
    },
    //处理编辑的数据
    handleOldData() {
      let formData = JSON.parse(JSON.stringify(this.form));
      formData.classifyId = formData.classifyId.join(",");
      formData.reportLableInfo = formData.reportLableInfo.join(",");
      formData.userLableInfo = formData.userLableInfo.join(",");
      if (formData.reportType === "2") {
        //判断类型是否为分析报告
        formData.reportUrl = "";
        formData.thumbnailUrl = "";
        formData.linkAdapt = null;
        formData.reportDocumentName = formData.reportDocumentUrl[0].response
          ? formData.reportDocumentUrl[0].response.name
          : formData.reportDocumentUrl[0].name;
        formData.reportDocumentUrl = formData.reportDocumentUrl[0].response
          ? formData.reportDocumentUrl[0].response.redirect_uri
          : formData.reportDocumentUrl[0].url;
      } else {
        formData.reportDocumentUrl = "";
        formData.reportDocumentName = "";
        if (formData.linkAdapt.length > 1) {
          //判断链接适配是否全部选中，全中传2，
          formData.linkAdapt = 2;
        } else {
          formData.linkAdapt = Number(formData.linkAdapt[0]);
        }
        formData.thumbnailUrl = formData.thumbnailUrl[0].response
          ? formData.thumbnailUrl[0].response.redirect_uri
          : formData.thumbnailUrl[0].url;
      }
      return formData;
    },
    //提交
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        //0 新增  1修改
        if (this.$route.query.isText == 0) {
          if (valid) {
            //获取处理后的数据
            if (!this.upFileType) {
              return false;
            }
            let formData = this.handleNewData();
            this.addReportList({
              form: formData,
              cb: (cb) => {
                this.success(cb);
                this.$router.push({ path: "/reportManagement/allReports" });
              },
              cbwarning: (cb) => {
                this.warning(cb);
                this.form.reportLableInfo = this.form.reportLableInfo ? this.form.reportLableInfo.split(",") : null;
                this.form.userLableInfo = this.form.userLableInfo ? this.form.userLableInfo.split(",") : null;
              },
            });
          } else {
            return false;
          }
        } else if (this.$route.query.isText == 1) {
          if (valid) {
            //获取处理后的数据
            if (!this.upFileType) {
              return false;
            }
            let formData = this.handleOldData();
            this.updateReportList({
              form: formData,
              cb: (cb) => {
                this.success(cb);
                this.$router.back(-1);
              },
              cbwarning: (cb) => {
                this.warning(cb);
                this.form.reportLableInfo = this.form.reportLableInfo ? this.form.reportLableInfo.split(",") : null;
                this.form.userLableInfo = this.form.userLableInfo ? this.form.userLableInfo.split(",") : null;
              },
            });
          } else {
            return false;
          }
        }
      });
    },
    //推送人群
    handleChangeFile({ fileList }) {
      if (fileList.length != 0) {
        let num = fileList[0].name.lastIndexOf(".");
        let type = fileList[0].name.substring(num + 1, fileList[0].name.length);
        const isJPG = type === "pdf";
        if (isJPG && this.checkSize) {
          this.form.reportDocumentUrl = fileList;
          this.$forceUpdate();
          if (fileList[0].response) {
            this.upFileType = true;
          }
        }
      } else {
        this.form.reportDocumentUrl = [];
        this.$forceUpdate();
      }
    },
    //推送人群上传文件之前校验文件类型
    beforeUploadFile(file) {
      let that = this;
      that.upFileType = false;
      let index = file.name.lastIndexOf(".");
      let type = file.name.substring(index + 1, file.name.length);
      const isJPG = type === "pdf";
      const isLt2M = file.size / 1024 / 1024 < 50;
      this.checkSize = isLt2M;
      if (!isLt2M) {
        this.$message.error("文件不可超过50M!");
        return false;
      }
      if (!isJPG) {
        that.$message.error("请上传PDF格式的文件!");
        return false;
      }
      return isJPG;
    },
    //取消
    close() {
      this.$router.back(-1);
    },
    //上传图片前校验
    beforeUpload(file) {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJpgOrPng && !isLt2M) {
        return this.$message.error("请上传jpeg/png/jpg格式图片且图片大小不可超过3M!");
      } else {
        if (!isJpgOrPng) {
          this.$message.error("请上传jpeg/png/jpg格式图片");
        }
        if (!isLt2M) {
          this.$message.error("图片大小不可超过3MB!");
        }
        return isJpgOrPng && isLt2M;
      }
    },
    //查看图片
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    //弹窗图片预览关闭弹窗
    previewHandleCancel() {
      this.previewVisible = false;
    },
    handleChange(data) {
      if (data.fileList.length == 0) {
        this.form.thumbnailUrl = [];
        return;
      }
      const isJpgOrPng =
        data.file.type === "image/jpeg" || data.file.type === "image/png" || data.file.type === "image/jpg";
      const isLt2M = data.file.size / 1024 / 1024 < 3;
      // //如果通过校验 再给图片赋值
      if (isJpgOrPng && isLt2M) {
        this.form.thumbnailUrl = data.fileList;
      }
    },
  },
};
</script>

<style scoped>
.mb-10 {
  margin-bottom: 10px;
}
::v-deep .ant-form-item-with-help {
  margin-bottom: 16px;
}
::v-deep .ant-modal-header {
  text-align: center;
  font-weight: bold;
}
::v-deep .ant-form-item {
  display: flex;
}
::v-deep[data-v-3ff1bbc0] .avatar-uploader > .ant-upload {
  width: 130px;
  height: 84px;
}

::v-deep .avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
::v-deep .ant-upload-select-picture-card i {
  font-size: 25px;
  color: #999;
}

::v-deep .ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.fotter {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
::v-deep .ant-modal-content > .ant-modal-close {
  top: -10px;
  right: -12px;
}
.uploadTip {
  font-size: 12px;
  line-height: 20px;
  color: #bfbfbf;
}
.up_file_width {
  margin-bottom: 10px;
}
::v-deep .ant-upload-list-item-name {
  max-width: 200px;
}
::v-deep .ant-form-item-control {
  line-height: 10px;
}
::v-deep .ant-form-item-control-wrapper {
  display: flex;
  align-items: center;
}
</style>
<style>
.ant-upload-list-picture-card .ant-upload-list-item {
  width: 150px !important;
}
</style>
